import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {PageTitle} from '../PageTitle';
import {UserContext} from '../globalContext';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../axiosInstance';
import logo from '../.././src/images/hemadesiludo.png';
import face from '../.././src/images/face8.jpg';
import wollet from '../images/wallet.32eb69bea0c4c697ebf9.png';
import { message } from 'antd';


const HomeScreen = props => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };
  
  const user = useContext(UserContext);
  let navigate = useNavigate();
  const today = new Date();
  const [todayUser, setTodayUser] = useState();
  const [messageData, setMessageData] = useState();
  const [todayRecharge, setTodayrecharge] = useState();
  const [todayWithdraw, setTodayWithdraw] = useState();
  const [todayProfit, setTodayProfit] = useState();
  const [todayCGame, setTodayCGame] = useState();
  const [todaySGame, setTodaySGame] = useState();
  const [todayCGame2, setTodayCGame2] = useState();
  const [todaySGame2, setTodaySGame2] = useState();
  
  const [allUser, setAllUser] = useState();
  const [allRecharge, setAllRecharge] = useState();
  const [allWithdraw, setAllWithdraw] = useState();
  const [allProfit, setAllProfit] = useState();
  const [allSGame, setAllSGame] = useState();
  const [allCGame, setAllCGame] = useState();
  const [allSGame2, setAllSGame2] = useState();
  const [allCGame2, setAllCGame2] = useState();
  const [allRechargeReq, setAllRechargeReq] = useState();
  const [allWithdrawReq, setAllWithdrawReq] = useState();
  const [allUnsolvedGame, setAllUnsolvedGame] = useState();
  const [allUnsolvedGame2, setAllUnsolvedGame2] = useState();
  useEffect(() => {

      let mounted = true;
      if (mounted) {


          if (user.userId == null) {
              window.location.reload(true);
          }


      }
      pageLoad();
      return () => (mounted = false);
  }, []);
  const pageLoad = () => {
      getTodayUser();
      getAllUser();
      getAllRecharge();
      getAllWithdraw();
      getAllProfit();
      getAllCGame();
      getAllSGame();
      getAllCGame2();
      getAllSGame2();
      getTodayRecharge();
      getTodayWithdraw();
      getTodayProfit();
      getTodayCGame();
      getTodaySGame();
      getTodayCGame2();
      getTodaySGame2();
      getAllRechargeReq();
      getAllWithdrawReq();
      getAllUnsolvedGame1();
      getAllUnsolvedGame2();
  }

const onSendMSG = () =>{
const data = {
  msg :messageData
}

axiosInstance.post(`/notification` , data).then((response) => {
  console.log("response",response.data)
  if(response.data.responseCode == 1){
     message.success("message sent successfully");
     setMessageData('')
  }
  else{
    message.error("message not sent ....!");

  }
});

}

  const getTodayUser = () => {
      const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      }
      // console.log("datadatadata",data)
      axiosInstance.post(`/member/report` , data).then((response) => {
          if(response.data.data.length>0){
              setTodayUser(...response.data.data);
          }
          else{
              setTodayUser({count:0})
          }
      });
      
  }
  const getAllUser = () => {
      
      axiosInstance.post(`/member/report`).then((response) => {
      // console.log("datadatadata",response)

          if(response.data.data.length>0){
              setAllUser(...response.data.data);
          }
          else{
              setAllUser({count:0})
          }
         
      });
  }
  const getTodayRecharge = () => {
      const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      }
      // console.log("data",data)
      axiosInstance.post(`/payment/report` , data).then((response) => {
          if(response.data.data.length>0){
              setTodayrecharge(...response.data.data);
          }
          else{
              setTodayrecharge({amount:0})
          }
      });
      
  }
  const getAllRecharge = () => {
      
      axiosInstance.post(`/payment/report`).then((response) => {

          if(response.data.data.length>0){
              setAllRecharge(...response.data.data);
          }
          else{
              setAllRecharge({amount:0})
          }
         
      });
  }
  const getTodayWithdraw = () => {
      const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      }
      axiosInstance.post(`/withdraw/report` , data).then((response) => {
          if(response.data.data.length>0){
              setTodayWithdraw(...response.data.data);
          }
          else{
              setTodayWithdraw({amount:0})
          }
      });
      
  }
  const getAllWithdraw = () => {
      
      axiosInstance.post(`/withdraw/report`).then((response) => {

          if(response.data.data.length>0){
              setAllWithdraw(...response.data.data);
          }
          else{
              setAllWithdraw({amount:0})
          }
         
      });
  }
  const getTodayProfit = () => {
      const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      }
      axiosInstance.post(`/profit/report` , data).then((response) => {
          if(response.data.data.length>0){
              setTodayProfit(...response.data.data);
          }
          else{
              setTodayProfit({amount:0})
          }
      });
      
  }
  const getAllProfit = () => {
      
      axiosInstance.post(`/profit/report`).then((response) => {

          if(response.data.data.length>0){
              setAllProfit(...response.data.data);
          }
          else{
              setAllProfit({amount:0})
          }
         
      });
  }
  const getTodaySGame = () => {
      const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      }
      axiosInstance.post(`/game/report` , data).then((response) => {

          if(response.data.data.length>0){
              setTodaySGame(...response.data.data);
          }
          else{
              setTodaySGame({count:0})
          }
      });
      
  }
  const getAllSGame = () => {
      
      axiosInstance.post(`/game/report`).then((response) => {

          if(response.data.data.length>0){
              setAllSGame(...response.data.data);
          }
          else{
              setAllSGame({count:0})
          }
         
      });
  }
  const getTodayCGame = () => {
      const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      }
      axiosInstance.post(`/game/report2` , data).then((response) => {
          if(response.data.data.length>0){
              setTodayCGame(...response.data.data);
          }
          else{
              setTodayCGame({count:0})
          }
      });
      
  }
  const getAllCGame = () => {
      
      axiosInstance.post(`/game/report2`).then((response) => {

          if(response.data.data.length>0){
              setAllCGame(...response.data.data);
          }
          else{
              setAllCGame({count:0})
          }
         
      });
  }

  const getTodaySGame2 = () => {
      const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      }
      axiosInstance.post(`/game2/report` , data).then((response) => {

          if(response.data.data.length>0){
              setTodaySGame2(...response.data.data);
          }
          else{
              setTodaySGame2({count:0})
          }
      });
      
  }
  const getAllSGame2 = () => {
      
      axiosInstance.post(`/game2/report`).then((response) => {

          if(response.data.data.length>0){
              setAllSGame2(...response.data.data);
          }
          else{
              setAllSGame2({count:0})
          }
         
      });
  }
  const getTodayCGame2 = () => {
      const data = {
          fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      }
      axiosInstance.post(`/game2/report2` , data).then((response) => {
          if(response.data.data.length>0){
              setTodayCGame2(...response.data.data);
          }
          else{
              setTodayCGame2({count:0})
          }
      });
      
  }
  const getAllCGame2 = () => {
      
      axiosInstance.post(`/game2/report2`).then((response) => {

          if(response.data.data.length>0){
              setAllCGame2(...response.data.data);
          }
          else{
              setAllCGame2({count:0})
          }
         
      });
  }
  const getAllRechargeReq = () => {
      
      axiosInstance.post(`/payment/report2`).then((response) => {

          if(response.data.data.length>0){
              setAllRechargeReq(...response.data.data);
          }
          else{
              setAllRechargeReq({count:0})
          }
         
      });
  }
  const getAllWithdrawReq = () => {
      
      axiosInstance.post(`/withdraw/report2`).then((response) => {

          if(response.data.data.length>0){
              setAllWithdrawReq(...response.data.data);
          }
          else{
              setAllWithdrawReq({count:0})
          }
         
      });
  }
  const getAllUnsolvedGame1 = () => {
      
      axiosInstance.post(`/game1f/report`).then((response) => {

          if(response.data.data.length>0){
              setAllUnsolvedGame(...response.data.data);
          }
          else{
              setAllUnsolvedGame({count:0})
          }
         
      });
  }
  const getAllUnsolvedGame2 = () => {
      
      axiosInstance.post(`/game2f/report`).then((response) => {

          if(response.data.data.length>0){
              setAllUnsolvedGame2(...response.data.data);
          }
          else{
              setAllUnsolvedGame2({count:0})
          }
         
      });
  }

  return (
    <div className={` ${isSidebarVisible ? 'sidebar-icon-only' : ''}`}>
      {/* //nav top header start */}
      <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a class="navbar-brand brand-logo" href="javascript:void(0)">
            <img src={logo} alt="logo" class="logo-dark" />
          </a>
          <a class="navbar-brand brand-logo-mini" href="javascript:void(0)"></a>
          <button
            class="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={handleToggleClick}>
            <span class="icon-menu"></span>
          </button>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center">
          <h5 class="mb-0 font-weight-medium d-none d-lg-flex">
            Welcome Your dashboard!
          </h5>
          <ul class="navbar-nav navbar-nav-right">
       
              <li>
              <div><a class="text-decoration-none text-white " href="javascriptvoid:(0)">
            <div class="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-2">
          <img className='wllet' src={wollet} alt="" srcset="" />
          {allProfit === undefined && <strong>0</strong>}
              {allProfit !== undefined && <strong>{allProfit.amount}</strong>}</div>
          </a></div>
              </li>
    

            <li class="nav-item dropdown d-none d-xl-inline-flex user-dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="UserDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <img
                  class="img-xs rounded-circle ms-2"
                  src={face}
                  alt="Profile image"
                />
                <span class="font-weight-normal"> Admin </span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="UserDropdown">
                <div class="dropdown-header text-center">
                  <img
                    class="img-md rounded-circle"
                    src={face}
                    alt="Profile image"
                  />
                  <p class="mb-1 mt-3">Admin</p>
                  <p class="font-weight-light text-muted mb-0">
                    Admin@gmail.com
                  </p>
                </div>
              
              
                <a class="dropdown-item"  onClick={()=>handleLogout()}>
                  <i class="dropdown-item-icon icon-power text-primary" ></i>Log
                  Out
                </a>
              </div>
            </li>
          </ul>
          <button
            class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
            onClick={handleToggleClick}>
            <span class="icon-menu"></span>
          </button>
        </div>
      </nav>
      {/* //nav top header end */}

      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper">
      {/* //sidebar start */}

          <nav
            class={`sidebar sidebar-offcanvas ${
              isSidebarVisible ? 'sidebar sidebar-offcanvas active' : ''
            }`}
            id="sidebar">
            <div
              class=""
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical">
              <ul class="nav">
                <li class="nav-item navbar-brand-mini-wrapper">
                  <a
                    class="nav-link navbar-brand brand-logo-mini"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true">
                    <i class="icon-screen-desktop menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item nav-profile">
                  <a href="javascript:void(0)" class="nav-link ">
                    <div class="profile-image">
                      <img
                        class="img-xs rounded-circle"
                        src={face}
                        alt="profile image"
                      />
                      <div class="dot-indicator bg-success"></div>
                    </div>
                    <div class="text-wrapper">
                      <p class="profile-name">Admin</p>
                      <p class="designation">Administrator</p>
                    </div>
                    <div class="icon-container mt-3">
                      <i class="icon-bubbles"></i>
                      <div class="dot-indicator bg-danger"></div>
                    </div>
                  </a>
                </li>

                <li class="nav-item active">
                  <a
                    class="nav-link active"
                  
                     href="#/HomeScreen">
                    <span class="menu-title">Dashboard</span>
                    <i class="icon-screen-desktop menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/ARecharge"
                   >
                    <span class="menu-title">Recharge Request</span>
                    <i class="icon-energy menu-icon"></i>

                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/AllRecharge"
                   >
                    <span class="menu-title"> All Recharge</span>
                    <i class="icon-energy menu-icon"></i>

                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/AllWithdraw"
                   >
                    <span class="menu-title"> All Withdraw</span>
                    <i class="icon-wallet menu-icon"></i>

                    
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                 
                   
                    href="#/member"
                    >
                    <span class="menu-title">Active User</span>
                    <i class="icon-user menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/deletedMember">
                    <span class="menu-title" >Deleted User</span>
                    <i class="icon-user-unfollow menu-icon"></i>

                  </a>
                </li>
             
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fCGame1"

                   >
                    <span class="menu-title">Reported Cancel G1</span>
                    <i class="icon-book-open menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fCGame2"
                   >
                    <span class="menu-title">Reported Cancel G2</span>
                    <i class="icon-wallet menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fGame1">
                   
                    <span class="menu-title">Reported Game 1</span>
                    <i class="icon-trophy menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/fGame2"
                   >
                    <span class="menu-title">Reported Game 2</span>
                    <i class="icon-grid menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#/cGame1"
                   >
                    <span class="menu-title">Cancled game 1</span>
                    <i class="icon-disc menu-icon"></i>
                  </a>
                </li>
           
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/cGame2" >
                    <span class="menu-title">Cancled game2</span>
                    <i class="icon-shield menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/sGame1"
                  >
                    <span class="menu-title">Success game 1</span>
                    <i class="icon-user-follow menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/sGame2" >
                    <span class="menu-title">Success game2</span>
                    <i class="icon-user-unfollow menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"
                  href="#/UpiScreen" >
                    <span class="menu-title">Account</span>
                    <i class="icon-user-unfollow menu-icon"></i>
                  </a>
                </li>
     
              </ul>
            </div>
          </nav>
      {/* //sidebar end */}

          <div class="main-panel">
            <div class="content-wrapper">
              <div>
                <div class="tab-content" id="v-pills-tabContent">
                <div
                    class="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab">
                      {/* your content */}

              

                    <div class="row quick-action-toolbar">
                      <div class="col-md-12 grid-margin">
                        <div class="card ">
                          <div class="card-header d-block d-md-flex">
                            <h5 class="mb-0">Send Messages</h5>
                          </div>
                          <div className="m-3">
                            <div class="input-container">
                              <input
                                required=""
                                placeholder="Type Message...."
                                type="text"
                                onChange={(e)=>setMessageData(e.target.value)}
                              />
                              <button class="invite-btn" type="button" onClick = {()=>onSendMSG()}>
                                Send Message
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 grid-margin">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="d-sm-flex align-items-baseline report-summary-header">
                                  <h5 class="font-weight-semibold">
                                    Report Summary
                                  </h5>{' '}
                                  <span class="ms-auto">Updated Report</span>{' '}
                                  <button class="btn btn-icons border-0 p-2">
                                    <i class="icon-refresh"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="row report-inner-cards-wrapper">
                              <div class=" col-md -6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Today & Total Users
                                  </span>
                                  {todayUser === undefined &&
                                    allUser === undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        0/0
                                      </div>
                                    )}
                                  {todayUser !== undefined &&
                                    allUser !== undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        {todayUser.count}/{allUser.count}
                                      </div>
                                    )}

                                  <a class="report-count" href="#/member">
                                    {' '}
                                    view all
                                  </a>
                                </div>
                                <div class="inner-card-icon bg-success">
                                  <i class="icon-user"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Today & Total Recharge
                                  </span>
                                  {todayRecharge === undefined &&
                                    allRecharge === undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        0/0
                                      </div>
                                    )}
                                  {todayRecharge !== undefined &&
                                    allRecharge !== undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        {todayRecharge.amount}/
                                        {allRecharge.amount}
                                      </div>
                                    )}

                                  <a class="report-count" href="#/ARecharge">
                                    {' '}
                                    view all
                                  </a>
                                </div>
                                <div class="inner-card-icon bg-danger">
                                  <i class="icon-energy menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Today & Total Withdr..
                                  </span>
                                  {todayWithdraw === undefined &&
                                    allWithdraw === undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        0/0
                                      </div>
                                    )}
                                  {todayWithdraw !== undefined &&
                                    allWithdraw !== undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        {todayWithdraw.amount}/
                                        {allWithdraw.amount}
                                      </div>
                                    )}

                                  <a class="report-count" href="#/AWithdrawl">
                                    {' '}
                                    view all
                                  </a>
                                </div>
                                <div class="inner-card-icon bg-warning">
                                <i class="icon-wallet menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Today & Total Profit
                                  </span>
                                  {todayProfit === undefined &&
                                    allProfit === undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        0/0
                                      </div>
                                    )}
                                  {todayProfit !== undefined &&
                                    allProfit !== undefined && (
                                      <div class="text-lg fw-bold  mybigdata">
                                        {todayProfit.amount}/{allProfit.amount}
                                      </div>
                                    )}

                                  <span class="report-count"> view all</span>
                                </div>
                                <div class="inner-card-icon bg-primary">
                                  <i class="icon-trophy menu-icon"></i>
                                </div>
                              </div>
                            </div>
                            <div class="row report-inner-cards-wrapper">
                              <div class=" col-md -6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Withdrawal Request
                                  </span>
                                  {allWithdrawReq === undefined && (
                                    <div class="text-lg fw-bold  mybigdata">
                                      0
                                    </div>
                                  )}
                                  {allWithdrawReq !== undefined && (
                                    <div class="text-lg fw-bold  mybigdata">
                                      {allWithdrawReq.count}
                                    </div>
                                  )}

                                  <a class="report-count" href="#/AWithdraw"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-success">
                                <i class="icon-book-open menu-icon"></i>

                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Recharge Request
                                  </span>
                                  {allRechargeReq === undefined && (
                                    <div class="text-lg fw-bold  mybigdata">
                                      0
                                    </div>
                                  )}
                                  {allRechargeReq !== undefined && (
                                    <div class="text-lg fw-bold  mybigdata">
                                      {allRechargeReq.count}
                                    </div>
                                  )}

                                  <a class="report-count" href="#/ARecharge"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-danger">
                                  <i class="icon-grid menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                    Unsolved Fraud G 1
                                  </span>
                                  <div className="d-flex">
                                  {allUnsolvedGame === undefined &&<div class="text-lg fw-bold  mybigdata">0</div>}
                                  {allUnsolvedGame !== undefined &&<div class="text-lg fw-bold  mybigdata">{allUnsolvedGame.count}</div>}
                                  </div>

                                  <a class="report-count" href="#/fGame1"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-warning">
                                <svg xmlns="http://www.w3.org/2000/svg" style={{height:'30px',fill:'#fff'}} viewBox="0 0 512 512"><path d="M256 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-95.2-8c-18.1 0-31.3 12.8-35.6 26.9c-8 26.2-32.4 45.2-61.2 45.2c-10 0-19.4-2.3-27.7-6.3c-7.6-3.7-16.7-3.3-24 1.2C.7 162.1-3.1 177.1 3.7 188.9L97.6 352l55.4 0-83-144.1c40.5-2.2 75.3-25.9 93.1-59.8c22 26.8 55.4 43.9 92.8 43.9s70.8-17.1 92.8-43.9c17.8 34 52.6 57.7 93.1 59.8L359 352l55.4 0 93.9-163.1c6.8-11.7 3-26.7-8.6-33.8c-7.3-4.5-16.4-4.9-24-1.2c-8.4 4-17.7 6.3-27.7 6.3c-28.8 0-53.2-19-61.2-45.2C382.5 100.8 369.3 88 351.2 88c-14.5 0-26.3 8.5-32.4 19.3c-12.4 22-35.9 36.7-62.8 36.7s-50.4-14.8-62.8-36.7C187.1 96.5 175.4 88 160.8 88zM133.2 432l245.6 0 16.6 32-278.7 0 16.6-32zm283.7-30.7c-5.5-10.6-16.5-17.3-28.4-17.3l-265 0c-12 0-22.9 6.7-28.4 17.3L68.6 452.5c-3 5.8-4.6 12.2-4.6 18.7c0 22.5 18.2 40.8 40.8 40.8l302.5 0c22.5 0 40.8-18.2 40.8-40.8c0-6.5-1.6-12.9-4.6-18.7l-26.5-51.2z"/></svg>

                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                  Unsolved Fraud G 2
                                  </span>
                                  <div className="d-flex">
                                  {allUnsolvedGame2 === undefined &&<div class="text-lg fw-bold  mybigdata">0</div>}
                                  {allUnsolvedGame2 !== undefined &&<div class="text-lg fw-bold  mybigdata">{allUnsolvedGame2.count}</div>}
                                  </div>

                                  <a class="report-count" href="#/fGame2"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" style={{height:'30px',fill:'#fff'}} viewBox="0 0 512 512"><path d="M256 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-95.2-8c-18.1 0-31.3 12.8-35.6 26.9c-8 26.2-32.4 45.2-61.2 45.2c-10 0-19.4-2.3-27.7-6.3c-7.6-3.7-16.7-3.3-24 1.2C.7 162.1-3.1 177.1 3.7 188.9L97.6 352l55.4 0-83-144.1c40.5-2.2 75.3-25.9 93.1-59.8c22 26.8 55.4 43.9 92.8 43.9s70.8-17.1 92.8-43.9c17.8 34 52.6 57.7 93.1 59.8L359 352l55.4 0 93.9-163.1c6.8-11.7 3-26.7-8.6-33.8c-7.3-4.5-16.4-4.9-24-1.2c-8.4 4-17.7 6.3-27.7 6.3c-28.8 0-53.2-19-61.2-45.2C382.5 100.8 369.3 88 351.2 88c-14.5 0-26.3 8.5-32.4 19.3c-12.4 22-35.9 36.7-62.8 36.7s-50.4-14.8-62.8-36.7C187.1 96.5 175.4 88 160.8 88zM133.2 432l245.6 0 16.6 32-278.7 0 16.6-32zm283.7-30.7c-5.5-10.6-16.5-17.3-28.4-17.3l-265 0c-12 0-22.9 6.7-28.4 17.3L68.6 452.5c-3 5.8-4.6 12.2-4.6 18.7c0 22.5 18.2 40.8 40.8 40.8l302.5 0c22.5 0 40.8-18.2 40.8-40.8c0-6.5-1.6-12.9-4.6-18.7l-26.5-51.2z"/></svg>

                                </div>
                              </div>
                      
                           
                            </div>
                            <div class="row report-inner-cards-wrapper">
                              <div class=" col-md -6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                  Today & All Game 1
                                  </span>
                                  {todaySGame === undefined && allSGame === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                  {todaySGame !== undefined && allSGame !== undefined &&<div class="text-lg fw-bold  mybigdata">{todaySGame.count}/{allSGame.count}</div>}

                                  <a class="report-count" href="#/sGame1"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-success">
                                  <i class="icon-user-follow menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                  Today & All Game 2
                                  </span>
                                  {todaySGame2 === undefined && allSGame2 === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                  {todaySGame2 !== undefined && allSGame2 !== undefined &&<div class="text-lg fw-bold  mybigdata">{todaySGame2.count}/{allSGame2.count}</div>}
                                  <a class="report-count" href="#/sGame2"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-danger">
                                  <i class="icon-user-follow menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                  Today & All cancel G 1
                                  </span>
                                  <div className="d-flex">
                                  {todayCGame === undefined && allCGame === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todayCGame !== undefined && allCGame !== undefined &&<div class="text-lg fw-bold  mybigdata">{todayCGame.count}/{allCGame.count}</div>}
                                  </div>

                                  <a class="report-count" href="#/cGame1"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-warning">
                                  <i class="icon-user-unfollow menu-icon"></i>
                                </div>
                              </div>
                              <div class="col-md-6 col-xl report-inner-card">
                                <div class="inner-card-text">
                                  <span class="report-title">
                                  Today & All cancel G 2
                                  </span>
                                  <div className="d-flex">
                                  {todayCGame2 === undefined && allCGame2 === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todayCGame2 !== undefined && allCGame2 !== undefined &&<div class="text-lg fw-bold  mybigdata">{todayCGame2.count}/{allCGame2.count}</div>}
                                  </div>

                                  <a class="report-count" href="#/cGame2"> view all</a>
                                </div>
                                <div class="inner-card-icon bg-primary">
                                  <i class="icon-user-unfollow menu-icon"></i>
                                </div>
                              </div>
                      
                           
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                   
                  

                

                      {/* your content end */}

                      </div>
                </div>
              </div>
            </div>

            <footer class="footer">
              <div class="d-sm-flex justify-content-center justify-content-sm-between">
                <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">
                  Copyright © 2024 Thartech. All rights reserved.{' '}
                  <a href="#"> Terms of use</a>
                  <a href="#">Privacy Policy</a>
                </span>
                <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                  {' '}
                  Made By <i class="icon-heart text-danger"></i> Thartech
                </span>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
